<template>
    <div>
        <!--订单详情页便捷操作-->
        <div class="top-wrapper">
            <div class="top-button" style="border-right: 0.1rem solid #eeeeee" @click="showCustomerServicePop">联系客服</div>
            <div class="top-button" style="border-right: 0.1rem solid #eeeeee" @click="viewReserve" v-if="info.express == 0">预约</div>
            <div class="top-button" @click="viewDetail">商品详情</div>
        </div>
        <customer-service ref="customerService"></customer-service>
        <div style="height: 4rem;width: 100%;"></div>
        <!--核销信息-->
        <order-check-body-common v-if="info.express == 0"
                                 :checkUrl="checkUrl"
                                 :productName="info.gooods_name"
                                 :specs="info.specs"
                                 :orderId="info.orderID"
                                 :randomCode="info.randomcode"
                                 :validEndTime="info.valid_end"
                                 :money="info.total_fee"
                                 :orderStatus="info.order_status"
                                 :front="front"
                                 :next="next"
                                 :statusStr="computedOrderStatus"
        ></order-check-body-common>
        <order-check-body-express v-else-if="info.express == 1"
                                  :productName="info.gooods_name"
                                  :specs="info.specs"
                                  :orderId="info.orderID"
                                  :name="express.name"
                                  :mobile="express.mobile"
                                  :address="express.detail"
                                  :province="express.province"
                                  :city="express.city"
                                  :county="express.district"
                                  :number="info.number"
                                  :statusStr="computedOrderStatus"
        ></order-check-body-express>
        <order-check-body-help-sell v-else-if="info.express == 2"
                                    :productName="info.gooods_name"
                                    :specs="info.specs"
                                    :orderId="info.orderID"
                                    :code="codeInfo.code"
                                    :validEndTime="info.valid_end"
                                    :money="info.total_fee"
        ></order-check-body-help-sell>
        <order-check-body-p-f-t v-else-if="info.express == 5"
                                :productName="info.gooods_name"
                                :specs="info.specs"
                                :orderId="info.orderID"
                                :code="info.randomcode"
                                :validEndTime="info.valid_end"
                                :money="info.total_fee"
                                :productId="info.goodsID"
                                :orderStatus="info.order_status"
                                :statusStr="computedOrderStatus"
        ></order-check-body-p-f-t>
        <order-check-body-package-ticket v-else-if="info.express == 6"
                                         :checkUrl="checkUrl"
                                         :productName="info.gooods_name"
                                         :specs="info.specs"
                                         :orderId="info.orderID"
                                         :randomCode="info.randomcode"
                                         :validEndTime="info.valid_end"
                                         :money="info.total_fee"
                                         :orderStatus="info.order_status"
                                         :useNumber="info.check_number"
                                         :allNumber="info.package_number"
                                         :statusStr="computedOrderStatus"
        ></order-check-body-package-ticket>
        <!--套餐内容-->
        <div class="info-wrapper" v-if="info.content != undefined && info.content.length">
            <common-title title="商品内容"></common-title>
            <div class="info-text-wrapper">
                <div class="content2" v-for="(items,index) in info.content" :key="index">
                    <div class="content3" style="width: 70vw;">
                        <p>{{index}}<span v-if="items.text">({{items.text}})</span></p>
                    </div>
                    <p v-if="items.price">{{items.price}}</p>
                </div>
            </div>
        </div>
        <!--备注信息-->
        <div class="info-wrapper" v-if="info.remark != undefined && info.remark.length">
            <common-title title="备注信息"></common-title>
            <div class="express_info" v-for="(items,index) in info.remark" style="border: none;padding: 0.5vw 2vw" :key="index">
                <p style="width: 100%;">{{items}}</p>
            </div>
        </div>
        <float-nav></float-nav>
    </div>
</template>

<script>
  import OrderCheckBodyCommon from '../../components/order/OrderCheckBodyCommon'
  import OrderCheckBodyExpress from '../../components/order/OrderCheckBodyExpress'
  import OrderCheckBodyPackageTicket from '../../components/order/OrderCheckBodyPackageTicket'
  import OrderCheckBodyHelpSell from '../../components/order/OrderCheckBodyHelpSell'
  import { globalConfig } from '../../utils/mixin'
  import CommonTitle from '../../components/order/CommonTitle'
  import FloatNav from '../../components/common/FloatNav'
  import { orderStatusStr } from '../../utils/tools'
  import OrderCheckBodyPFT from '../../components/order/OrderCheckBodyPFT'
  import CustomerService from '../../components/common/CustomerService'
  export default {
    name: 'Order',
    components: { CustomerService, OrderCheckBodyPFT, FloatNav, CommonTitle, OrderCheckBodyHelpSell, OrderCheckBodyPackageTicket, OrderCheckBodyExpress, OrderCheckBodyCommon },
    mixins: [globalConfig],
    data () {
      return {
        orderId: '',
        info: [],
        checkUrl: '',
        express: [],
        codeInfo: [],
          front: [],
          next: []
      }
    },
    methods: {
      getOrderInfo: function () {
        const that = this
        this.axios.post(this.apiUrl + 'mall/init/order', {
          id: this.orderId
        }).then((response) => {
          const data = response.data
          console.log(data)
          that.info = data.info
          that.checkUrl = data.checkUrl
          that.express = data.express
          that.codeInfo = data.codeInfo
            that.front = data.near.front
            that.next = data.near.next
        })
      },
      // 展示客服面板
      showCustomerServicePop: function () {
        this.$refs.customerService.showPop()
      },
      viewDetail: function () {
        this.$router.push('/product/' + this.info.product_cell_id)
      },
      //    针对type为0的场景，允许跳转预约
      viewReserve: function () {
        this.$router.push(`/subscribe/subscribe/${this.info.businessid}/${this.info.id}`)
      }
    },
    computed: {
      computedOrderStatus: function () {
        return orderStatusStr(this.info.express, this.info.order_status)
      }
    },
    created () {
      this.orderId = this.$route.params.id
      this.getOrderInfo()
    },
      watch: {
          $route: function (to, from) {
              this.orderId = this.$route.params.id
              this.getOrderInfo()
          }
      }
  }
</script>

<style scoped>
    * {
        box-sizing: border-box;
    }
    .top-wrapper {
        display: flex;
        flex-flow: row;
        justify-content: space-around;
        align-items: center;
        border-bottom: 0.1rem solid #eee;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        background-color: #ffffff;
    }
    .top-button {
        padding: 1.2rem 0;
        text-align: center;
        /*width: 33.3%;*/
        font-weight: 600;
        flex: 1;
    }
    .top-button-line {
        background-color: #eeeeee;
        height: 2rem;
        width: 0.1rem;
    }
    .info-wrapper{
        background-color: #fff;
        width: 96vw;
        padding: 3vw;
        margin: 3vw 2vw;
        box-shadow: 0 0 8px #ccc;
        border-radius: 1.5vw;
    }
    .express_info{
        display: flex;
        width: 95%;
        border-top: 1px solid #eee;
        font-size: 3.4vw;
        color: #666;
        padding: 1.8vw 2vw;
        margin: 0 auto;
    }
    .info-text-wrapper{
        display: flex;
        flex-flow: column;
        width: 95%;
        font-size: 3.4vw;
        color: #666;
        margin: 0 auto;
        border: none;
        padding: 0.5vw 2vw
    }
    .info-text-wrapper p:nth-child(1){
        width: 60vw;
        color: #333;
    }
    .express_info p:nth-child(1){
        width: 18vw;
        color: #333;
    }
    .express_info p:nth-child(2){
        width: 60vw;
        color: #333;
    }
    .content2{
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding:0.5vw 1vw;
    }
    .content3 span{
        font-size: 2.8vw;
        color: #888;
        margin: 0 0.6vw;
    }
</style>
