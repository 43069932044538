<!--票付通商品 express = 5-->
<template>
    <div>
        <div class="check-wrapper">
            <div class="check-title-wrapper">
                <common-title title="订单信息"></common-title>
                <router-link class="tip-wrapper" to="/guidPft">
                    <div class="tip-text">如何使用兑换码</div>
                    <img class="tip-icon" src="https://seetop-1257860468.file.myqcloud.com/html/img/icon_ticket1126.png">
                </router-link>
            </div>
            <div class="check-item-wrapper" v-if="orderStatus == 0">
                <p>核销码</p>
                <p style="display: flex;align-items: center;width: 50%;">{{code}}</p>
                <div class="copy-button" @click="toUse">前往使用</div>
            </div>
            <div class="check-item-wrapper">
                <p>商品名</p>
                <p>
                    <span style="margin-right: 3vw">{{productName}}</span>
                    <span v-for="items in specs" :key="items">
                    {{items}}
                    </span>
                </p>
            </div>
            <div class="check-item-wrapper">
                <p>订单号</p>
                <p>{{orderId}}</p>
            </div>
            <div class="check-item-wrapper">
                <p>过期时间</p>
                <p>{{validEndTime}}</p>
            </div>
            <div class="check-item-wrapper">
                <p>状态</p>
                <p style="color: #fa1f1f;">{{statusStr}}</p>
            </div>
            <div class="check-item-wrapper">
                <p>温馨提示</p>
                <p>请复制兑换码，前往兑换预约</p>
            </div>
            <div class="check-item-wrapper" style="justify-content:space-between;">
                <p></p>
                <p style="width: 30vw;text-align: right;">共计：<span style=";color:#ff3b30"> ￥{{computedMoney}}</span></p>
            </div>
        </div>
    </div>
</template>

<script>
  // import VueQr from 'vue-qr'
  import CommonTitle from './CommonTitle'
  import { moneyFen2Yuan } from '../../utils/tools'
  export default {
    name: 'OrderCheckBodyPFT',
    components: { CommonTitle },
    props: ['productName', 'specs', 'orderId', 'code', 'validEndTime', 'money', 'productId', 'statusStr', 'orderStatus'],
    computed: {
      computedMoney: function () {
        return moneyFen2Yuan(this.money)
      }
    },
    methods: {
      toUse: function () {
        window.location.href = 'https://club.seetop.net.cn/web/booking/ticketing.html?product_id=' + this.productId + '&code=' + this.code
      }
    }
  }
</script>

<style scoped>
    * {
        box-sizing: border-box;
    }
    .check-wrapper{
        background-color: #fff;
        width: 96vw;
        padding: 3vw;
        margin: 3vw 2vw;
        box-shadow: 0 0 8px #ccc;
        border-radius: 1.5vw;
    }
    .check-title-wrapper {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
    }
    .tip-wrapper {
        display:flex;
        align-items: center;
        border-bottom: 1px solid #999;
        height: 5vw;
    }
    .tip-text {
        font-size: 3.2vw;
        color: #999;
    }
    .tip-icon {
        height: 3vw;
        margin: 0 0 0 1vw;
    }
    .check-item-wrapper{
        display: flex;
        width: 95%;
        border-top: 1px solid #eee;
        font-size: 3.4vw;
        color: #666;
        padding: 1.8vw 2vw;
        margin: 0 auto;
    }
    .check-item-wrapper p:nth-child(1){
        width: 18vw;
        color: #333;
    }
    .check-item-wrapper p:nth-child(2){
        width: 60vw;
        color: #333;
    }
    .check-tab-wrapper div{
        width:50%;
        text-align:center;
        padding:0.5rem;
        font-size:3.6vw;
        color:#333333
    }
    .check-tab-wrapper div span{
        padding:0.2rem 0.3rem
    }
    .copy-button {
        background-color: #e2a15d;
        word-break:keep-all;    /* 不换行 */
        white-space:nowrap;
        text-align: center;
        border-radius: 0.3rem;
        padding: 0 0.3rem;
        box-sizing: border-box;
        color: #e3e3e3;
    }
</style>
