<!--第三方串货 express = 2-->
<template>
    <div>
        <div class="check-wrapper">
            <common-title title="订单信息"></common-title>
            <div class="check-item-wrapper" style="justify-content: center;">
                <vue-qr id="qr" :text="code"></vue-qr>
            </div>
            <div class="check-item-wrapper">
                <p>核销码</p>
                <div class="code" style="">{{code}}</div>
                <div class="copy-button" @click="string2Clipboard(code)">点击复制</div>
            </div>
            <div class="check-item-wrapper">
                <p>商品名</p>
                <p>
                    <span style="margin-right: 3vw">{{productName}}</span>
                    <span v-for="items in specs" :key="items">
                    {{items}}
                    </span>
                </p>
            </div>
            <div class="check-item-wrapper">
                <p>订单号</p>
                <p>{{orderId}}</p>
            </div>
            <div class="check-item-wrapper">
                <p>过期时间</p>
                <p>{{validEndTime}}</p>
            </div>
            <div class="check-item-wrapper">
                <p>温馨提示</p>
                <p>请复制兑换码，前往相应渠道兑换商品</p>
            </div>
            <div class="check-item-wrapper" style="justify-content:space-between;">
                <p></p>
                <p style="width: 30vw;text-align: right;">共计：<span style=";color:#ff3b30"> ￥{{computedMoney}}</span></p>
            </div>
        </div>
    </div>
</template>

<script>
  import VueQr from 'vue-qr'
  import CommonTitle from './CommonTitle'
  import { moneyFen2Yuan, string2Clipboard } from '../../utils/tools'
  export default {
    name: 'OrderCheckBodyHelpSell',
    components: { CommonTitle, VueQr },
    props: ['productName', 'specs', 'orderId', 'code', 'validEndTime', 'money'],
    computed: {
      computedMoney: function () {
        return moneyFen2Yuan(this.money)
      }
    },
    methods: {
      string2Clipboard
    },
    mounted () {
      console.log(this.code)
    }
  }
</script>

<style scoped>
    * {
        box-sizing: border-box;
    }
    .check-wrapper{
        background-color: #fff;
        width: 96vw;
        padding: 3vw;
        margin: 3vw 2vw;
        box-shadow: 0 0 8px #ccc;
        border-radius: 1.5vw;
    }
    .check-item-wrapper{
        display: flex;
        width: 95%;
        border-top: 1px solid #eee;
        font-size: 3.4vw;
        color: #666;
        padding: 1.8vw 2vw;
        margin: 0 auto;
    }
    .check-item-wrapper p:nth-child(1){
        width: 18vw;
        color: #333;
    }
    .check-item-wrapper p:nth-child(2){
        width: 60vw;
        color: #333;
    }
    .check-tab-wrapper div{
        width:50%;
        text-align:center;
        padding:0.5rem;
        font-size:3.6vw;
        color:#333333
    }
    .check-tab-wrapper div span{
        padding:0.2rem 0.3rem
    }
    .copy-button {
        background-color: #b2b6ba;
        word-break:keep-all;    /* 不换行 */
        white-space:nowrap;
        text-align: center;
        border-radius: 0.3rem;
        padding: 0 0.3rem;
        box-sizing: border-box;
        color: #000000;
        height: fit-content;
    }
    .code {
        display: flex;
        align-items: center;
        width: 50%;
        word-wrap: break-word;
        word-break: break-all;
    }
</style>
