<template>
    <div class="title-wrapper">
        <p></p>
        <p>{{title}}</p>
    </div>
</template>

<script>
  export default {
    name: 'CommonTitle',
    props: ['title']
  }
</script>

<style scoped>
    .title-wrapper{
        height: 8vw;
        display: flex;
        align-content: center;
    }
    .title-wrapper p:nth-child(1){
        width: 0.8vw;
        height: 3vw;
        background-color: #ff3b30;
        margin: 1.2vw 1.5vw 0 2.5vw;
    }
    .title-wrapper p:nth-child(2){
        font-size: 3.6vw;
        font-weight: 700;;
    }
</style>
