<!--快递商品 express = 1-->
<template>
        <div class="check-wrapper">
            <common-title title="订单信息"></common-title>
            <div class="check-item-wrapper">
                <p>订单号</p>
                <p>{{orderId}}</p>
            </div>
            <div class="check-item-wrapper">
                <p>商品名</p>
                <p>
                    <span style="margin-right: 3vw">{{productName}}</span>
                    <span v-for="items in specs" :key="items">
                    {{items}}
                    </span>
                </p>
            </div>
            <div class="check-item-wrapper">
                <p>数量</p>
                <p>{{number}}</p>
            </div>
            <div class="check-item-wrapper">
                <p>收件人</p>
                <p>{{name}}</p>
            </div>
            <div class="check-item-wrapper">
                <p>电话号码</p>
                <p>{{mobile}}</p>
            </div>
            <div class="check-item-wrapper">
                <p>收货地址</p>
                <p>{{province}} {{city}} {{county}}</p>
            </div>
            <div class="check-item-wrapper">
                <p>地址详情</p>
                <p>{{address}}</p>
            </div>
            <div class="check-item-wrapper">
                <p>状态</p>
                <p style="color: #fa1f1f;">{{statusStr}}</p>
            </div>
        </div>
</template>

<script>
  import CommonTitle from './CommonTitle'
  export default {
    name: 'OrderCheckBodyExpress',
    components: { CommonTitle },
    props: ['productName', 'specs', 'orderId', 'name', 'mobile', 'address', 'province', 'city', 'county', 'number', 'statusStr']
  }
</script>

<style scoped>
    * {
        box-sizing: border-box;
    }
    .check-wrapper{
        background-color: #fff;
        width: 96vw;
        padding: 3vw;
        margin: 3vw 2vw;
        box-shadow: 0 0 8px #ccc;
        border-radius: 1.5vw;
    }
    .check-item-wrapper{
        display: flex;
        width: 95%;
        border-top: 1px solid #eee;
        font-size: 3.4vw;
        color: #666;
        padding: 1.8vw 2vw;
        margin: 0 auto;
    }
    .check-item-wrapper p:nth-child(1){
        width: 18vw;
        color: #333;
    }
    .check-item-wrapper p:nth-child(2){
        width: 60vw;
        color: #333;
    }
</style>
